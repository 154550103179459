/**
 * n ms 대기
 * @param n
 */
export function wait(n: number) {
  return new Promise(resolve => setTimeout(resolve, n))
}

/**
 * worker를 waitTimeout 간격으로 n번 retry
 * @param worker
 * @param timeout
 * @param retry
 */
export async function retryAndTimeout({
  worker,
  waitTimeout,
  retry
}: {
  worker: () => Promise<void>
  waitTimeout: number
  retry: number
}): Promise<void> {
  retry--
  await wait(waitTimeout)
  try {
    await worker()
  } catch (err) {
    if (retry < 1) {
      throw err
    }
    await retryAndTimeout({
      worker,
      waitTimeout,
      retry
    })
  }
}

/**
 * URL 생성
 */
export function generateUrl({
  origin,
  params
}: {
  origin: string
  params?: { [key in string]: string }
}) {
  if (params) {
    const urlParams = new URLSearchParams()
    for (const key in params) {
      if (params[key]) {
        urlParams.append(key, params[key] + '')
      }
    }
    return origin + '?' + urlParams.toString()
  }
  return origin
}

/**
 * uuid 생성
 */
export function uuidV4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0
    const v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
