import { EdkHostSessionInfo } from './host-session'

export type EdkClientSessionChangeEventFunction = (session: EdkHostSessionInfo | null) => void;

export default class EdkClientSession {
  static isInit: boolean
  private static stage: string
  private static isDebug = false
  private readonly stageKey: string
  private readonly storageKey: string
  private readonly storageSessionKey: string
  private readonly storage: Storage

  constructor(storage: Storage, private onChangeSession?: EdkClientSessionChangeEventFunction) {
    if (!EdkClientSession.isInit) {
      throw new Error('EDK: Client 세션이 초기화 되어있지 않습니다.')
    }
    this.stageKey = 'edk:' + EdkClientSession.stage
    this.storageKey = this.stageKey + ':client:'
    this.storageSessionKey = this.storageKey + ':session'
    this.storage = storage
    /** clientSession 초기화 */
    this.removeSession()
  }

  static init(options: {
    stage: string
    debug: boolean
  }) {
    if (EdkClientSession.isInit) {
      throw new Error('EDK: 이미 Client 세션이 초기화 되었습니다.')
    }
    EdkClientSession.stage = options.stage
    EdkClientSession.isDebug = options.debug
    EdkClientSession.isInit = true
  }

  /**
   * 세션 추가
   */
  addSession(session: EdkHostSessionInfo) {
    if (this.onChangeSession) {
      this.onChangeSession(session)
    }
    this.storage.setItem(this.storageSessionKey, JSON.stringify(session))
  }

  /**
   * 세션 업데이트
   */
  updateSession(session: EdkHostSessionInfo) {
    const prevSession = this.getSession()
    if (!prevSession) {
      throw new Error('세션이 존재하지 않습니다.')
    }
    if (prevSession.syncId !== session.syncId) {
      this.removeSession()
      return null
    }
    const updateSession = {
      ...prevSession,
      ...session
    }
    if (this.onChangeSession) {
      this.onChangeSession(updateSession)
    }
    this.storage.setItem(this.storageSessionKey, JSON.stringify(updateSession))
  }

  /**
   * 세션 정보
   */
  getSession(): EdkHostSessionInfo | null {
    const result = this.storage.getItem(this.storageSessionKey)
    if (result === null) {
      return null
    }
    return JSON.parse(result)
  }

  /**
   * 세션 삭제
   */
  removeSession() {
    if (this.onChangeSession) {
      this.onChangeSession(null)
    }
    this.storage.removeItem(this.storageSessionKey)
  }

  /**
   * 세션 비우기
   */
  reset() {
    for (let i = 0; i < this.storage.length; i++) {
      const key = this.storage.key(i) || ''
      if (key.indexOf(this.storageSessionKey) === 0) {
        this.storage.removeItem(key)
      }
    }
  }
}
