import EdkClientSession, { EdkClientSessionChangeEventFunction } from './client-session'
import { EdkClientEvent, EdkHostEvent } from './event'
import { edkInfo } from './info'
import { EdkUserEventParams } from './user-event'

type EdkStage = 'prod' | 'qa' | 'dv' | 'local'

export default class EdkClient {
  private static _stage: 'prod' | 'qa' | 'dv' | 'local'
  private static _isInit = false
  private static _isDebug = false
  private static _handshakeId = ''
  private static _onChangeSession?: EdkClientSessionChangeEventFunction
  private static _id = ''
  private static _clientId = ''
  private static _isEventListener = false
  private static _session: EdkClientSession
  private static _info = edkInfo

  get id() {
    return EdkClient._id
  }

  get clientId() {
    return EdkClient._clientId
  }

  get handshakeId() {
    return EdkClient._handshakeId
  }

  get isDebug() {
    return EdkClient._isDebug
  }

  get stage() {
    return EdkClient._stage
  }

  set stage(value: string) {
    if (['prod', 'qa', 'dv'].includes(value)) {
      throw new Error(`잘못된 스테이지가 입력되었습니다. (${value})`)
    }
    EdkClient._stage = 'dv'
  }

  get session() {
    return EdkClient._session
  }

  get info() {
    return EdkClient._info
  }

  constructor() {
    if (!EdkClient._isInit) {
      throw new Error('EDK: Client가 초기화되지 않았습니다.')
    }
  }

  /**
   * !!!!주의!!!! 외부에 공개되는 함수, 변경시 공유 필요
   * 호스트 초기화, 세션 초기화, 컨테이너 초기화
   */
  public static init({
    id,
    onChangeSession,
    debug
  }: {
    id: string
    debug: boolean
    onChangeSession?: EdkClientSessionChangeEventFunction
  }): void {
    if (!EdkClient._isInit) {
      EdkClient._isInit = true
      const urlParams = new URLSearchParams(window.location.search)
      const stageParam = urlParams.get('stage')
      if (stageParam && !['prod', 'qa', 'dv', 'local'].includes(stageParam)) {
        EdkClient._isInit = false
        throw new Error(`EDK: ${stageParam} 지원하지 않는 스테이지`)
      }
      EdkClient._stage = (stageParam as EdkStage) || 'prod'
      EdkClient._id = id
      EdkClient._isDebug = debug
      EdkClient._onChangeSession = onChangeSession
      EdkClientSession.init({
        debug,
        stage: EdkClient._stage
      })
      EdkClient._session = new EdkClientSession(sessionStorage, EdkClient._onChangeSession)
      if (!EdkClient._isEventListener) {
        window.addEventListener('message', ({ data }) => {
          EdkClient._handShake(data)
        })
        EdkClient._isEventListener = true
      }
      EdkClient._logger('INIT', {
        version: EdkClient._info.version,
        isInit: EdkClient._isInit,
        stage: EdkClient._stage,
        isDebug: EdkClient._isDebug,
        clientId: EdkClient._clientId
      })
      EdkClient._sendEdkHost({
        id: EdkClient._id,
        clientId: EdkClient._clientId,
        type: 'client',
        action: 'ready',
        message: 'EDK Client 준비 완료',
        data: {
          clientEdkVersion: EdkClient._info.version
        }
      })
    }
  }

  /**
   * client 종료
   */
  close() {
    EdkClient._sendEdkHost({
      id: this.id,
      clientId: this.clientId,
      handshakeId: EdkClient._handshakeId,
      type: 'client',
      action: 'close',
      message: `EDK Client 종료`
    })
  }

  /**
   * 애널리틱스 수집 도구에 활용될 유저 이벤트
   * @param event
   */
  userEvent(event: EdkUserEventParams) {
    if (event.name === 'page_view' && event.params?.page) {
      event.params.page = this.trim(event.params.page)
    }
    EdkClient._sendEdkHost({
      id: this.id,
      handshakeId: EdkClient._handshakeId,
      clientId: this.clientId,
      type: 'client',
      action: 'analytics',
      message: `${event.name} 유저 이벤트 `,
      data: event
    })
  }

  /**
   * 호스트 레이아웃 변경 전달
   * @param data
   */
  updateHostLayout(data: { hideTabs: boolean }) {
    EdkClient._sendEdkHost({
      id: EdkClient._id,
      handshakeId: EdkClient._handshakeId,
      clientId: EdkClient._clientId,
      type: 'client',
      action: 'layout',
      message: 'Host 레이아웃 변경',
      data
    })
  }

  /**
   * 호스트 딥링크 이벤트 전달
   * @param data
   */
  sendDeeplink(data: { link: string }) {
    EdkClient._sendEdkHost({
      id: EdkClient._id,
      handshakeId: EdkClient._handshakeId,
      clientId: EdkClient._clientId,
      type: 'client',
      action: 'deeplink',
      message: 'Host DeepLink 요청',
      data
    })
  }

  /**
   * 호스트 링크 이벤트 전달
   * @param data
   */
  sendLink(data: { url: string }) {
    EdkClient._sendEdkHost({
      id: EdkClient._id,
      handshakeId: EdkClient._handshakeId,
      clientId: EdkClient._clientId,
      type: 'client',
      action: 'link',
      message: 'Host Link 변경 요청',
      data
    })
  }

  /**
   * 호스트 링크 이벤트 전달
   * @param data
   */
  shareKakao(data: { templateId: string; templateArgs?: Record<string, string> }) {
    EdkClient._sendEdkHost({
      id: this.id,
      handshakeId: EdkClient._handshakeId,
      clientId: this.clientId,
      type: 'client',
      action: 'kakao-share',
      message: `${data.templateId} 카카오톡 공유하기 요청`,
      data
    })
  }

  /**
   * 인앱 브라우저 오픈 이벤트 전달
   * @param data
   */
  openInAppBrowser(data: { link: string }) {
    EdkClient._sendEdkHost({
      id: EdkClient._id,
      handshakeId: EdkClient._handshakeId,
      clientId: EdkClient._clientId,
      type: 'client',
      action: 'iab',
      message: 'Host InAppBrowser 오픈 요청',
      data
    })
  }

  /**
   * 문자열 trim
   * @param s
   * @private
   */
  private trim(s: string) {
    return s && s.toString().replace(/^\s+|\s+$/g, '')
  }

  /**
   * host에 이벤트 전달
   * @param event
   * @private
   */
  private static _sendEdkHost(event: EdkClientEvent) {
    window.parent.postMessage(event, '*')
    EdkClient._logger('SEND', event)
  }

  /**
   * Host handShake
   * @param event
   */
  private static _handShake(event: EdkHostEvent) {
    if (event.type === 'host') {
      let isSessionUpdate = false
      switch (event.action) {
        case 'handshake':
          EdkClient._clientId = event.clientId
          if (event.data.session) {
            EdkClient._session.addSession(event.data.session)
            isSessionUpdate = !!EdkClient._session.getSession()
          }
          if (event.data.handshakeId) {
            EdkClient._handshakeId = event.data.handshakeId
          }
          EdkClient._sendEdkHost({
            id: this._id,
            clientId: this._clientId,
            handshakeId: this._handshakeId,
            type: 'client',
            action: 'handshake',
            message: '메시지 핸드쉐이크 성공',
            data: {
              id: this._id,
              clientEdkVersion: EdkClient._info.version,
              isSessionUpdate
            }
          })
          break
      }
    }
  }

  /**
   * 로그
   * @param key
   * @param data
   * @private
   */
  private static _logger(key: string, ...data: any[]) {
    if (EdkClient._isDebug) {
      // eslint-disable-next-line no-console
      console.log(`[EDK:CLIENT:${key}] `, ...data)
    }
  }
}
