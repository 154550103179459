export interface PdkUserLoginEvent {
  name: 'login'
  params: {
    method: string
  }
}

export interface PdkUserAuthorizedEvent {
  name: 'authorized'
  params: {
    method: string
  }
}

export interface PdkUserSignUpEvent {
  name: 'sign_up'
  params: {
    method: string
  }
}

export interface PdkUserBtnClickEvent {
  name: 'btn_click'
  params: {
    id: string
    key?: string
    label: string
  }
}

export interface PdkUserActionEvent {
  name: 'action'
  params: {
    key: string
    label: string
  }
}

export interface PdkUserContentEvent {
  name: 'content_view'
  params: {
    published_date: string
    content_title: string
    key: string
  }
}

export interface PdkPageViewEvent {
  name: 'page_view'
  params: {
    page: string
    title?: string
  }
}

export interface PdkUserNavClickEvent {
  name: 'nav_click'
  params: {
    id: string
    label: string
    nav_type: 'global' | 'gnb_sub' | 'local' | 'bottom'
  }
}

export interface PdkUserIdEvent {
  name: 'tab_click' | 'banner_click' | 'quick_menu_click' | 'banner_view'
  params: {
    label: string
    id: string
  }
}

export interface PdkUserKeyEvent {
  name: 'form_submit' | 'list_item_click' | 'modal_view'
  params: {
    label: string
    key: string
  }
}

export type EdkUserEventParams =
  | PdkUserAuthorizedEvent
  | PdkUserLoginEvent
  | PdkUserSignUpEvent
  | PdkUserActionEvent
  | PdkUserBtnClickEvent
  | PdkPageViewEvent
  | PdkUserNavClickEvent
  | PdkUserContentEvent
  | PdkUserIdEvent
  | PdkUserKeyEvent

export type EdkUserEventListener = (evnet: EdkUserEventParams) => void

export class UserEvent {
  constructor(private config: {
    userEventListener?: EdkUserEventListener
  }) {
  }

  /**
   * 유저 이벤트 발생
   * @param event
   */
  emit(event: EdkUserEventParams) {
    if (this.config.userEventListener) {
      this.config.userEventListener(event);
    }
  }
}

export default UserEvent
