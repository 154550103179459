export class Logger {
  private readonly prefix: string
  private readonly isDebug: boolean

  constructor(config: { prefix: string, isDebug: boolean }) {
    this.prefix = `[EDK:${config.prefix.toUpperCase()}]`
    this.isDebug = config.isDebug
  }

  log(...data: any[]) {
    console.log(this.prefix, data)
  }

  trace(...data: any[]) {
    if (this.isDebug) {
      console.trace(this.prefix, ...data)
    }
  }

  static staticLog(...data: any[]) {
    console.trace(...data)
  }
}

export default Logger
