import { EdkEventListener, EdkEventSendTrigger } from './event'
import EdkHostSession, { EdkHostSessionInfo } from './host-session'
import EdkIframeComponent from './iframe-component'
import { edkInfo } from './info'
import Logger from './logger'
import { EdkSso, EdkSsoOption } from './sso'
import { EdkUserEventListener, UserEvent } from './user-event'
import { generateUrl } from './util'

type EdkHostConfig = {
  appId?: string
  clientId: string
  stage: EdkStage
  originStages?: EdkDetailStage
  debug?: boolean
  sso?: EdkSsoOption
  onSendEvent?: EdkEventSendTrigger
  eventListener?: EdkEventListener
  userEventListener?: EdkUserEventListener
}
type EdkStage = 'prod' | 'qa' | 'dv' | 'local'
type EmbedTarget =
  | 'mobileV4WebApp'
  | 'authBznavApi'
  | 'ssoBznavWebApp'
  | 'insightLocaBznavWebApp'
  | 'embedBznavWebApp'
  | 'insightBznavWebApp'
  | 'insightReportBznavWebApp'
type EdkDetailStage = {
  [target in EmbedTarget]: EdkStage
}
type EdkOrigin = {
  [target in EmbedTarget]: string
}

export default class EdkHost {
  private static _isInit = false
  private static _isSync = false
  private static _config: EdkHostConfig
  private static _stage: EdkStage
  private static _originStages: EdkDetailStage
  private static _origins: EdkOrigin
  private static _edkIframeInstances: Array<{
    id: string
    edkIframe: EdkIframeComponent
  }> = []
  private static _isDebug = false
  private static _appId: string
  private static _clientId: string
  private static _info = edkInfo
  private static _userEvent: UserEvent
  private static _session: EdkHostSession
  private static _sso: EdkSso
  private logger: Logger
  public info = edkInfo
  public activeIframeUrl = ''

  constructor() {
    this.logger = new Logger({
      prefix: 'HOST',
      isDebug: this.isDebug
    })
    if (!EdkHost._isInit) {
      this.logger.trace('EdkHost 초기화에 실패 하였습니다.')
    }
  }

  get isInit() {
    return EdkHost._isInit
  }

  get config() {
    return EdkHost._config
  }

  get stage() {
    return EdkHost._stage
  }

  get appId() {
    return EdkHost._appId
  }

  get clientId() {
    return EdkHost._clientId
  }

  get session() {
    return EdkHost._session
  }

  get isDebug() {
    return EdkHost._isDebug
  }

  get userEvent() {
    return EdkHost._userEvent
  }

  get sso() {
    if (!EdkHost._sso) {
      throw new Error('EdK SSO가 설정되지 않음')
    }
    return EdkHost._sso
  }

  /**
   * !!!!주의!!!! 외부에 공개되는 함수, 변경시 공유 필요
   * 호스트 초기화, 세션 초기화, 컨테이너 초기화
   */
  static init(config: EdkHostConfig): void {
    if (!EdkHost._isInit) {
      const stage = config.stage || 'prod'
      const {
        appId,
        clientId,
        originStages,
        debug,
        userEventListener,
        sso
      } = config
      EdkHost._config = {
        ...config,
        stage
      }
      EdkHost._isInit = true
      EdkHost._stage = stage || 'prod'
      EdkHost._isDebug = !!debug
      EdkHost._appId = appId || clientId
      EdkHost._clientId = clientId
      EdkHost._originStages = {
        mobileV4WebApp: stage,
        authBznavApi: stage,
        ssoBznavWebApp: stage,
        insightLocaBznavWebApp: stage,
        embedBznavWebApp: stage,
        insightBznavWebApp: stage,
        insightReportBznavWebApp: stage,
        ...originStages
      }
      EdkHost.appendEdkStyle()
      EdkHost._origins = EdkHost.getOrigins(EdkHost._originStages)
      EdkHost._session = new EdkHostSession({
        storage: localStorage,
        stage,
        isDebug: !!debug
      })
      Logger.staticLog('INIT', {
        version: EdkHost._info.version,
        stage: EdkHost._stage,
        isDebug: EdkHost._isDebug,
        clientId: EdkHost._clientId,
        originStages: EdkHost._originStages,
        origins: EdkHost._origins
      })
      EdkHost._userEvent = new UserEvent({
        userEventListener
      })
      if (sso) {
        EdkHost._sso = new EdkSso({
          config: sso,
          session: EdkHost._session,
          userEvent: EdkHost._userEvent,
          clientId: EdkHost._clientId,
          stage: EdkHost._stage,
          isDebug: EdkHost._isDebug
        })
      }
    }
  }

  /**
   * config 업데이트
   * @param options
   */
  updateConfig(options: EdkHostConfig) {
    EdkHost._isInit = false
    this.logger.trace('UPDATE_CONFIG')
    EdkHost.init(options)
  }

  /**
   * stage 에따른 origin 정보 획득
   * @param originStages
   */
  static getOrigins(originStages: EdkDetailStage) {
    const results: EdkOrigin = {
      mobileV4WebApp: '',
      authBznavApi: '',
      ssoBznavWebApp: '',
      insightLocaBznavWebApp: '',
      embedBznavWebApp: '',
      insightBznavWebApp: '',
      insightReportBznavWebApp: ''
    }
    Object.keys(originStages).map((key, index) => {
      const target = key as EmbedTarget
      const stage = originStages[target]
      const combineKey = `${key}:${stage}`
      switch (combineKey) {
        case 'mobileV4WebApp:local':
          results[target] = 'http://localhost:3000'
          break
        case 'mobileV4WebApp:dv':
          results[target] = 'https://dv.m.bznav.com'
          break
        case 'mobileV4WebApp:qa':
          results[target] = 'https://qa.m.bznav.com'
          break
        case 'mobileV4WebApp:prod':
          results[target] = 'https://m.bznav.com'
          break
        case 'authBznavApi:local':
          results[target] = 'http://localhost:8787'
          break
        case 'authBznavApi:dv':
          results[target] = 'https://auth.dv.api.bznav.com'
          break
        case 'authBznavApi:qa':
          results[target] = 'https://auth.dv.api.bznav.com'
          break
        case 'authBznavApi:prod':
          results[target] = 'https://auth.api.bznav.com'
          break
        case 'embedBznavWebApp:local':
          results[target] = 'http://localhost:4001'
          break
        case 'embedBznavWebApp:dv':
          results[target] = 'https://dv.embed.bznav.com'
          break
        case 'embedBznavWebApp:qa':
          results[target] = 'https://qa.embed.bznav.com'
          break
        case 'embedBznavWebApp:prod':
          results[target] = 'https://embed.bznav.com'
          break
        case 'ssoBznavWebApp:local':
          results[target] = 'http://localhost:4600'
          break
        case 'ssoBznavWebApp:qa':
          results[target] = 'https://dv-sso.bznav.com'
          break
        case 'ssoBznavWebApp:dv':
          results[target] = 'https://dv-sso.bznav.com'
          break
        case 'ssoBznavWebApp:prod':
          results[target] = 'https://sso.bznav.com'
          break
        case 'insightLocaBznavWebApp:local':
          results[target] = 'http://localhost:3900'
          break
        case 'insightLocaBznavWebApp:dv':
          results[target] = 'https://dv-loca.bznav.com'
          break
        case 'insightLocaBznavWebApp:qa':
          results[target] = 'https://dv-loca.bznav.com'
          break
        case 'insightLocaBznavWebApp:prod':
          results[target] = 'https://loca.bznav.com'
          break
        case 'insightBznavWebApp:local':
          results[target] = 'http://localhost:4002'
          break
        case 'insightBznavWebApp:dv':
          results[target] = 'https://dv-insight.bznav.com'
          break
        case 'insightBznavWebApp:qa':
          results[target] = 'https://dv-insight.bznav.com'
          break
        case 'insightBznavWebApp:prod':
          results[target] = 'https://insight.bznav.com'
          break
        case 'insightReportBznavWebApp:local':
          results[target] = 'http://localhost:4003'
          break
        case 'insightReportBznavWebApp:dv':
          results[target] = 'https://dv-insight-report.bznav.com'
          break
        case 'insightReportBznavWebApp:qa':
          results[target] = 'https://dv-insight-report.bznav.com'
          break
        case 'insightReportBznavWebApp:prod':
          results[target] = 'https://insight-report.bznav.com'
          break
        default:
          console.error(new Error(`${combineKey} 알 수 없는 스테이지`))
      }
    })
    return results
  }

  /**
   * !!!!주의!!!! 외부에 공개되는 함수, 변경시 공유 필요
   * 비즈넵 회원가입
   * @param args
   */
  async syncBznav({ dom, bznavSyncToken }: { dom?: HTMLElement; bznavSyncToken: string }) {
    if (!bznavSyncToken) {
      this.logger.trace('SYNC', 'SyncToken not found')
      return
    }
    const syncUrl = EdkHost._origins.authBznavApi + '/loca/sync'
    const body = {
      appId: this.appId,
      clientId: this.clientId,
      bznavSyncToken
    }
    const result = await window.fetch(syncUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    const session = ((await result.json()) as unknown) as EdkHostSessionInfo
    this.logger.trace('SYNC', body)
    EdkHost._session.addSession(session)
    EdkHost._isSync = true
    return
  }

  /**
   * 비즈넵 로그아웃
   * @param args
   */
  async signOutBznav({ dom }: { dom?: HTMLElement }) {
    const session = EdkHost._session.getSession()
    if (!session) {
      this.logger.trace('SIGNOUT', '로그인 되지 않았습니다')
      return
    }
    const syncUrl = EdkHost._origins.authBznavApi + '/signout'
    const body = {
      appId: this.appId,
      clientId: this.clientId,
      bznavSyncToken: session.syncToken
    }
    const result = await window.fetch(syncUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    this.logger.trace('SIGNOUT', {
      result
    })
    EdkHost._session.removeSession()
    return
  }

  /**
   * !!!!주의!!!! 외부에 공개되는 함수, 변경시 공유 필요
   * 데이터싱크 오픈
   */
  async openDevtool({
    origin,
    dom,
    eventListener
  }: {
    dom?: HTMLElement
    origin?: string
    onSendEvent?: EdkEventSendTrigger
    eventListener?: EdkEventListener
  }): Promise<EdkIframeComponent> {
    const id = 'edk-embed-bznav'
    const url = (origin || EdkHost._origins.embedBznavWebApp) + '/devtool'
    this.activeIframeUrl = url
    return await this.appendIframe({
      url,
      id,
      dom,
      eventListener
    })
  }

  /**
   * 지원 상담
   * @param surveyId
   * @param origin
   * @param dom
   * @param eventListener
   */
  async openSupportSurvey({
    surveyId,
    origin,
    dom,
    eventListener
  }: {
    surveyId?: string
    dom?: HTMLElement
    origin?: string
    onSendEvent?: EdkEventSendTrigger
    eventListener?: EdkEventListener
  }): Promise<EdkIframeComponent> {
    const id = 'edk-embed-bznav'
    let url = origin || EdkHost._origins.embedBznavWebApp
    if (this.clientId === 'bznav') {
      url = url + '/bznav/supports/' + surveyId || ''
    } else {
      url = url + '/mock/supports/' + surveyId || ''
    }
    this.activeIframeUrl = url
    return await this.appendIframe({
      url,
      id,
      dom,
      eventListener
    })
  }

  /**
   * !!!!주의!!!! 외부에 공개되는 함수, 변경시 공유 필요
   * 데이터싱크 오픈
   */
  async openDataSync({
    dom,
    origin,
    orgSyncId,
    orgAlias,
    eventListener
  }: {
    orgSyncId?: string
    orgAlias?: string
    dom?: HTMLElement
    origin?: string
    onSendEvent?: EdkEventSendTrigger
    eventListener?: EdkEventListener
  }): Promise<EdkIframeComponent> {
    if (!EdkHost._isSync) {
      try {
        const syncToken = this.getSellySyncBznavToken()
        if (syncToken) {
          await this.syncBznav({
            bznavSyncToken: syncToken
          })
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
    const id = 'edk-embed-bznav'
    const stage = EdkHost._stage
    const version = EdkHost._info.version
    let url = origin || EdkHost._origins.embedBznavWebApp
    if (orgSyncId) {
      const syncOrg = await this.getSyncOrg({
        orgSyncId
      })
      orgAlias = syncOrg.orgAlias
      if (syncOrg.status === 'CONFIRM') {
        url = url + '/error/require-sync-org'
        this.activeIframeUrl = url
        return await this.appendIframe({
          url,
          id,
          dom,
          eventListener
        })
      }
    }
    if (this.clientId === 'selly') {
      url = url + '/selly/datasync?orgAlias=' + orgAlias
    } else if (orgAlias) {
      url = url + '/bznav/datasync?orgAlias=' + orgAlias
    } else {
      url = url + '/error/client-not-found'
    }
    url = url + (url.indexOf('?') === -1 ? '?' : '&') + `stage=${stage}`
    url = url + (url.indexOf('?') === -1 ? '?' : '&') + `v=${decodeURI(version)}`
    this.activeIframeUrl = url
    return await this.appendIframe({
      url,
      id,
      dom,
      eventListener
    })
  }

  /**
   * !!!!주의!!!! 외부에 공개되는 함수, 변경시 공유 필요
   * 인사이트 오픈
   */
  async openInsightLoca({
    dom,
    origin,
    orgSyncId,
    eventListener
  }: {
    dom?: HTMLElement
    origin?: string
    orgSyncId: string
    onSendEvent?: EdkEventSendTrigger
    eventListener?: EdkEventListener
  }): Promise<EdkIframeComponent> {
    if (!EdkHost._isSync) {
      try {
        const syncToken = this.getSellySyncBznavToken()
        if (syncToken) {
          await this.syncBznav({
            bznavSyncToken: syncToken
          })
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
    const id = 'embed-insight-loca'
    const syncOrg = await this.getSyncOrg({
      orgSyncId
    })
    const url =
      (origin || EdkHost._origins.insightLocaBznavWebApp) + '?orgAlias=' + syncOrg.orgAlias
    this.activeIframeUrl = url
    return await this.appendIframe({
      url,
      id,
      dom,
      eventListener
    })
  }

  /**
   * !!!!주의!!!! 외부에 공개되는 함수, 변경시 공유 필요
   * 인사이트 오픈
   */
  async openInsight({
    alias,
    dom,
    origin,
    redirect,
    eventListener
  }: {
    alias: string
    dom?: HTMLElement
    origin?: string
    redirect?: string
    onSendEvent?: EdkEventSendTrigger
    eventListener?: EdkEventListener
  }): Promise<EdkIframeComponent> {
    const id = 'edk-insight'
    const url = `${origin ||
      EdkHost._origins.insightBznavWebApp}/edk?timestamp=${Date.now()}&alias=${alias}${
      redirect ? `&redirect=${redirect}` : ''
    }`
    this.activeIframeUrl = url
    return await this.appendIframe({
      url,
      id,
      dom,
      eventListener
    })
  }

  /**
   * !!!!주의!!!! 외부에 공개되는 함수, 변경시 공유 필요
   * 인사이트 오픈
   */
  async openInsightReport({
    orgAlias,
    clientAlias,
    dom,
    origin,
    eventListener
  }: {
    orgAlias: string
    clientAlias?: string
    dom?: HTMLElement
    origin?: string
    onSendEvent?: EdkEventSendTrigger
    eventListener?: EdkEventListener
  }): Promise<EdkIframeComponent> {
    const id = 'edk-insight'
    let url = (origin || EdkHost._origins.insightReportBznavWebApp) + '?orgAlias=' + orgAlias
    if (clientAlias) {
      url = url + '&clientAlias=' + clientAlias
    }
    this.activeIframeUrl = url
    return await this.appendIframe({
      url,
      id,
      dom,
      eventListener
    })
  }

  /**
   * !!!!주의!!!! 외부에 공개되는 함수, 변경시 공유 필요
   * Mobile V4 오픈
   */
  async openMobileV4({
    orgAlias,
    clientAlias,
    dom,
    path,
    params,
    origin,
    eventListener
  }: {
    orgAlias: string
    clientAlias?: string
    dom?: HTMLElement
    path?: string
    params?: any
    origin?: string
    onSendEvent?: EdkEventSendTrigger
    eventListener?: EdkEventListener
  }): Promise<EdkIframeComponent> {
    const id = 'edk-mobile-v4'
    origin = origin || EdkHost._origins.mobileV4WebApp
    const url = generateUrl({
      origin: origin + path,
      params: {
        orgAlias,
        clientAlias,
        ...params
      }
    })
    this.activeIframeUrl = url
    return await this.appendIframe({
      url,
      id,
      dom,
      eventListener
    })
  }

  /**
   * !!!!주의!!!! 외부에 공개되는 함수, 변경시 공유 필요
   * 포커스 비즈넵 오픈
   */
  async openMobileMf({
    orgAlias,
    clientAlias,
    dom,
    path,
    params,
    origin,
    eventListener
  }: {
    orgAlias: string
    clientAlias?: string
    dom?: HTMLElement
    path?: string
    params?: any
    origin?: string
    onSendEvent?: EdkEventSendTrigger
    eventListener?: EdkEventListener
  }): Promise<EdkIframeComponent> {
    const id = 'edk-focus-bznav'
    origin = origin || 'https://focus.bznav.com'
    const url = generateUrl({
      origin: origin + path,
      params: {
        orgAlias,
        clientAlias,
        ...params
      }
    })
    this.activeIframeUrl = url
    return await this.appendIframe({
      url,
      id,
      dom,
      eventListener
    })
  }

  /**
   * 추가 ssoFlow 진행
   * @param dom
   * @param ssoFlow
   * @param syncToken
   * @param eventListener
   * @private
   */
  private async openSsoFlow({
    dom,
    ssoFlow,
    syncToken,
    eventListener
  }: {
    dom?: HTMLElement
    ssoFlow: string
    syncToken: string
    onSendEvent?: EdkEventSendTrigger
    eventListener?: EdkEventListener
  }) {
    this.logger.trace('SSO:FLOW', '')
    const id = 'edk-sso'
    const url = EdkHost._origins.ssoBznavWebApp + '/edk'
    this.activeIframeUrl = url
    const requireClientFlow = false
    if (requireClientFlow) {
      return await this.appendIframe({
        url,
        id,
        dom,
        eventListener
      })
    }
  }

  /**
   * Ifrmae
   * @param key
   * @private
   */
  private async appendIframe({
    url,
    dom,
    id,
    eventListener
  }: {
    url: string
    dom?: HTMLElement
    id: string
    onSendEvent?: EdkEventSendTrigger
    eventListener?: EdkEventListener
  }): Promise<EdkIframeComponent> {
    if (!EdkHost._isInit) {
      throw new Error('EDK: 호스트가 초기화 되지 않았습니다.')
    }
    const stage = EdkHost._stage
    url = url + (url.indexOf('?') === -1 ? '?' : '&') + `stage=${stage}`
    const instance = EdkHost._edkIframeInstances.find(instance => instance.id === id)
    if (instance) {
      instance.edkIframe.appendComponent({
        dom: dom,
        url
      })
      return instance.edkIframe
    } else {
      const edkIframe = new EdkIframeComponent(this, id, {
        url,
        dom,
        eventListener
      })
      EdkHost._edkIframeInstances.push({
        id,
        edkIframe: edkIframe
      })
      return edkIframe
    }
  }

  /**
   * orgSyncId를 변경
   * @param orgSyncId
   * @private
   */
  async getSyncOrg({ orgSyncId }: { orgSyncId: string }) {
    const session = this.session.getSession()
    const fullbackOrgAlias = EdkHost._appId.toLowerCase() + '_' + orgSyncId.toLowerCase()
    if (!session) {
      console.error('세션이 존재하지 않습니다.')
      return {
        orgAlias: fullbackOrgAlias,
        status: 'ACTIVE'
      }
    }
    const syncOrg = session.syncOrgs?.find(org => org.orgSyncId)
    if (!syncOrg) {
      console.error('연동된 사업체가 존재하지 않습니다.')
      return {
        orgAlias: fullbackOrgAlias,
        status: 'ACTIVE'
      }
    }
    return {
      orgAlias: syncOrg.orgAlias || fullbackOrgAlias,
      status: syncOrg.syncStatus || 'ACTIVE'
    }
  }

  /**
   * edk style 추가
   * @private
   */
  private static appendEdkStyle() {
    const styles = `
       .edk-debug {
          position: fixed;
          color: #dadada;
          padding-right: 10px;
          bottom: 1px;
          font-size: 10px;
          z-index: 999999;
       }
       .edk-spinner {
          display: inline-block;
          width: 80px;
          height: 80px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, -50%)
        }
        .edk-spinner div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: 44px;
          height: 44px;
          margin: 4px;
          border: 4px solid #fff;
          border-radius: 50%;
          animation: edk-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: #dcdcdc transparent transparent transparent;
        }
        .edk-spinner div:nth-child(1) {
          animation-delay: -0.45s;
        }
        .edk-spinner div:nth-child(2) {
          animation-delay: -0.3s;
        }
        .edk-spinner div:nth-child(3) {
          animation-delay: -0.15s;
        }
        @keyframes edk-spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
    `
    const styleSheet = document.createElement('style')
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet)
  }

  /**
   * 셀리 토큰 획득
   * @private
   */
  private getSellySyncBznavToken() {
    let result = null
    if (localStorage) {
      const auth = localStorage && localStorage.getItem('auth')
      if (auth) {
        const authJson = JSON.parse(auth)
        result = authJson.bzNavToken
      }
    }
    if (EdkHost._stage !== 'prod') {
      this.appendDebugText(result ? 'O' : 'X')
    }
    return result
  }

  /**
   * 디버그 텍스트 추가
   * @param text
   * @private
   */
  private appendDebugText(text: string) {
    const div = document.createElement('span')
    div.className = 'edk-debug'
    div.innerHTML = text
    document.body.appendChild(div)
  }
}
